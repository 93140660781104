import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainHome from "./page/MainHome";
import SignIn from "./page/SignIn";
import SignUp from "./page/SignUp";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./style/home.css";
import SideBar from "./component/SideBar";
// import Agenda from "./page/Agenda";
import Tasks from "./page/Tasks";
// import AudioRecorder from "./component/AudioRecorder";
import { useState } from "react";
import Step4 from "./component/Step4";
// import Profile from "./component/Profile";
import Step5 from "./component/Step5";
import WalkThrough from "./page/WalkThrough";
import Admin from "./page/Admin";
import OverviewPage from "./page/OverviewPage";
import RecordingDetails from "./page/RecordingDetails";
// import Step2 from "./component/Step2";
import VoiceRecorder from "./component/VoiceRecorder";
// import { L } from "./langauge/english.lang";
import ForgotPassword from "./page/ForgotPassword";
import ChangePassword from "./page/ChangePassword";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./component/ProtectedRoute";
import AddUser from "./page/AddUser";
import AddAppointment from "./page/AddAppointment";
import MicrosoftSignIn from "./page/MicrosoftSignIn";
import MicrosoftLogin from "./component/Auth/MicrosoftLogin";

function App() {
  const [privacyMode, setPrivacyMode] = useState(false);
  const [activeStep, setStep] = useState(1);
  const [page, setPage] = useState("START"); // Adjust according to your actual initial page constant from L.LINK

  // function isLoggedIn() {
  //   const token = sessionStorage.getItem(TOKEN_KEY);
  //   console.log("Token:", token); // Check the output in the browser console
  //   return !!token;
  // }

  const setActiveStep = (step) => {
    setStep(step);
  };

  const [formData, setFormData] = useState({
    client_name: "",
    client_number: "",
  });

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <SideBar
                privacyMode={privacyMode}
                setPrivacyMode={setPrivacyMode}
                page={page}
                setPage={setPage}
              />
            }
          >
            <Route
              path="home"
              element={
                <ProtectedRoute>
                  <MainHome
                    privacyMode={privacyMode}
                    setFormData={setFormData}
                    setActiveStep={setActiveStep}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="overview"
              element={
                <ProtectedRoute>
                  <OverviewPage privacyMode={privacyMode} />
                </ProtectedRoute>
              }
            />
            <Route
              path="tasks"
              element={
                <ProtectedRoute>
                  <Tasks />
                </ProtectedRoute>
              }
            />
            <Route
              path="home/walkthrough"
              element={
                <ProtectedRoute>
                  <WalkThrough activeStep={activeStep} />
                </ProtectedRoute>
              }
            >
              <Route
                path="record"
                element={
                  <VoiceRecorder
                    setActiveStep={setActiveStep}
                    setPage={setPage}
                  />
                }
              />
              <Route
                path="review"
                element={
                  <Step4 setActiveStep={setActiveStep} setPage={setPage} />
                }
              />
              <Route
                path="wrap-up"
                element={
                  <Step5 setActiveStep={setActiveStep} setPage={setPage} />
                }
              />
            </Route>
            <Route
              path="admin"
              element={
                <ProtectedRoute adminOnly={true}>
                  <Admin privacyMode={privacyMode} />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-user"
              element={
                <ProtectedRoute adminOnly={true}>
                  <AddUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-appointment"
              element={
                <ProtectedRoute adminOnly={true}>
                  <AddAppointment />
                </ProtectedRoute>
              }
            />
            <Route
              path="recording-details"
              element={
                <ProtectedRoute>
                  <RecordingDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="change-password"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />

            <Route path="" element={<Navigate to="/home" />} />
          </Route>
          <Route path="*" element={<Navigate to="/microsoft-sign-in" />} />
          <Route path="/handle-login" element={<MicrosoftLogin />} />
          <Route path="/microsoft-sign-in" element={<MicrosoftSignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
