import React from 'react';
import { API } from '../../constants/api.const';


const MicrosoftAuthComponent = () => {
  const handleMicrosoftLogin = async () => {
    try {
      const loginUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=69b87eb0-a72e-43b0-be30-92affb5a5442&response_type=code&redirect_uri=${encodeURIComponent(API.MICROSOFT_HANDLE_LOGIN)}&response_mode=query&scope=offline_access%20user.read%20Calendars.Read&state=12345`;
      window.location.href = loginUrl;
    } catch (error) {
      console.error('Error during Microsoft login:', error);
    }
  };

  return (
    <div style={{'display': 'flex','justifyContent':'center', 'width':'100%'}}>
      <button onClick={handleMicrosoftLogin} className='microsoft-btn'></button>
    </div>
  );
};

export default MicrosoftAuthComponent;

