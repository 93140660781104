// import React, { useEffect, useState } from 'react'
// import axios from "axios";
// import { API, AUTH_HEADER } from "../constants/api.const";
// import EditNoteIcon from "@mui/icons-material/EditNote";
// import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import "../style/agenda.scss";
// import { L } from '../langauge/english.lang';
// import { useNavigate } from 'react-router-dom';

// function Agenda({privacyMode}) {

//   const [agenda, setAgenda] = useState([]);

//   // const formatDate = (dateString, options) => {
//   //   const date = new Date(dateString);
//   //   return new Intl.DateTimeFormat("en-In", options).format(date);
//   // };
//   const formatDate = (dateString, options = {}) => {
//     const date = new Date(dateString);
//     if (options.shortFormat) {
//       const days = ['Sun.', 'Mon.', 'Tues.', 'Wed.', 'Thurs.', 'Fri.', 'Sat.'];
//       const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

//       const day = days[date.getDay()];
//       const month = months[date.getMonth()];

//       return `${day} ${month} ${date.getDate()} ${date.getFullYear()}`;
//     }
//     return new Intl.DateTimeFormat("en-In", options).format(date);
//   };

//   const formatDateForAPI = (dateString) => {
//     const date = new Date(dateString);
//     return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
//   };

//   const [selectedDate, setSelectedDate] = useState(new Date())
//   const navigate = useNavigate()

//   // const edit = (apt) =>{
//   //   navigate("/home/walkthrough/record", { state: { apt: {id : apt.id, client_name : apt.patient.first_name+" "+apt.patient.last_name} } });
//   // }
//   const edit = (apt) => {
//     if (apt.is_appointment) {
//       navigate("/home/walkthrough/record", { state: { apt: {id : apt.id, client_name : apt.patient.first_name + (apt.patient.last_name ? " " + apt.patient.last_name : "")} } });
//     }
//   }

//   useEffect(() => {
//       axios
//         .get(API.GET_APPOINTMENTS + `?is_scheduled=true`, AUTH_HEADER)
//         .then((res) => {
//           if (res && res.data) setAgenda(res.data.data);
//         })
//         .catch((e) => console.log(e));
//   }, [selectedDate]);
//   return (
//     <div className='agenda agenda-main' >
//         <div className="left">
//          <div className="head">
//          {L.TODAY_CONSULTATION} <span className="today-date">- {formatDate(new Date(), { shortFormat: true })}</span>
//          {/* <h3 className='date'> {formatDate(selectedDate.toISOString(), {weekday : 'long' })}, {formatDate(selectedDate.toISOString(), {dateStyle : 'medium' })}  </h3> */}
//          </div>
//          {agenda.length>0?( <div className="list">
//             {agenda.map((item) => (
//               <div className="item" onClick={e=>edit(item)}>
//                 <div className="seg1">
//                 <div className="time">
//                     {formatDate(item.appointment_time, {
//                       timeStyle: "short",
//                     })}
//                   </div>
//                 </div>
//                 <div className="seg2">
//                 <div className='top'>
//                 <div className="name">
//                     {/* {item.patient.first_name+" "+item.patient.last_name} */}
//                     {item.patient.first_name + (item.patient.last_name ? " " + item.patient.last_name : "")}
//                 </div>
//                 {/* <MoreHorizIcon className='icon'/> */}
//                 </div>
//                   <div className="desc">

//                   {item.description}
//                   </div>

//                 </div>
//               </div>
//             ))}
//           </div>):(
//             <div className='norecord'> {L.NO_CONSULTATION} </div>
//           )}

//       </div>

//       {/* <div className="right">
//       <LocalizationProvider dateAdapter={AdapterDayjs} className='cal'>
//       <DateCalendar onChange={e=>setSelectedDate(e)} />
//     </LocalizationProvider>
//       </div> */}
//     </div>
//   )
// }

// export default Agenda

import React, { useEffect, useState } from "react";
import axios from "axios";
import { API, getAuthHeader } from "../constants/api.const";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "../style/agenda.scss";
import { L } from "../langauge/english.lang";
import { useNavigate } from "react-router-dom";

const Agenda = React.memo(({ privacyMode, selectedDate }) => {
  const [agenda, setAgenda] = useState([]);
  const navigate = useNavigate();

  const formatDate = (dateString, options = {}) => {
    const date = new Date(dateString);
    if (options.shortFormat) {
      const days = [
        "zondag", // Sunday
        "maandag", // Monday
        "dinsdag", // Tuesday
        "woensdag", // Wednesday
        "donderdag", // Thursday
        "vrijdag", // Friday
        "zaterdag", // Saturday
      ];
      const months = [
        "januari", // January
        "februari", // February
        "maart", // March
        "april", // April
        "mei", // May
        "juni", // June
        "juli", // July
        "augustus", // August
        "september", // September
        "oktober", // October
        "november", // November
        "december", // December
      ];

      const day = days[date.getDay()];
      const month = months[date.getMonth()];
      return `${day} ${date.getDate()} ${month} ${date.getFullYear()}`;
    }
    return new Intl.DateTimeFormat("nl-NL", options).format(date);
  };

  const formatDateForAPI = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };
  const formatTime = (dateString) => {
    const date = new Date(dateString);

    // Use Intl.DateTimeFormat with timeZone set to 'UTC' to prevent conversion
    return new Intl.DateTimeFormat("nl-NL", {
      timeStyle: "short",
      timeZone: "UTC",
    }).format(date);
  };

  const edit = (apt) => {
    if (apt.is_appointment) {
      navigate("/home/walkthrough/record", {
        state: {
          apt: {
            id: apt.id,
            client_name:
              apt.patient.first_name +
              (apt.patient.last_name ? " " + apt.patient.last_name : ""),
          },
        },
      });
    }
  };
  const dt = new Date();
  const today = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
  useEffect(() => {
    axios
      .get(API.GET_APPOINTMENTS + `?is_scheduled=true&date=${selectedDate}`, {
        headers: getAuthHeader(),
      })
      .then((res) => {
        if (res && res.data) setAgenda(res.data.data);
      })
      .catch((e) => console.log(e));
  }, [selectedDate]);

  return (
    <div className="agenda agenda-main middle">
      <div className="left">
        <div className="head">
          {L.TODAY_CONSULTATION}
          <span className="today-date">
            - {formatDate(new Date(), { shortFormat: true })}
          </span>
        </div>
        {agenda.length > 0 ? (
          <div className="list">
            {agenda.map((item) => (
              <div
                key={item.id}
                className={`item ${item.is_appointment ? "clickable" : ""}`}
                onClick={() => edit(item)}
                style={{ cursor: item.is_appointment ? "pointer" : "default" }}
              >
                <div className="seg1">
                  <div className="time">
                    {formatTime(item.appointment_time)}
                  </div>
                </div>
                <div className="seg2">
                  <div className="top">
                    <div className="name">
                      {item.patient.first_name +
                        (item.patient.last_name
                          ? " " + item.patient.last_name
                          : "")}
                      {"  "}
                    </div>
                    {/* {item.subject && <span>DOB : {item.subject}</span>} */}
                  </div>
                  {item.subject && (
                    <div className="dob">{item.subject}</div>
                  )}
                  <div className="desc">{item.description}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="norecord"> {L.NO_CONSULTATION} </div>
        )}
      </div>
      {/* Commented out calendar section
      <div className="right">
        <LocalizationProvider dateAdapter={AdapterDayjs} className='cal'>
          <DateCalendar onChange={e=>setSelectedDate(e)} />
        </LocalizationProvider>
      </div> */}
    </div>
  );
});


export default Agenda;
